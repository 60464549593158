import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import AdSenseMultiplex from '../components/AdSense/AdSenseMultiplex'

const TagTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`
  return (
    <Layout>
      <div className="blog-tags">
        <h1>{tagHeader}</h1>
        <br />
        <ul className="tag-list">
          {edges.map(({ node }) => {
            const { title, date } = node.frontmatter
            const { slug } = node.fields
            return (
              <div>
                <span key={slug}>
                  <Link to={slug}>{title}</Link>
                  <small>
                    <span>&nbsp;&nbsp;| {date}</span>
                  </small>
                </span>
              </div>
            )
          })}
        </ul>
        <span>
          <Link to="/tags">← All tags</Link>
        </span>
        <div className="tags-bottom-ads">
          <AdSenseMultiplex />
        </div>
      </div>
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___created], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            created(formatString: "MMMM DD, YYYY")
            updated(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
